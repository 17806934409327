"use client";

import React, { useState, useEffect } from "react";
import SearchIcon from "../../../../public/assets/search.svg";
import styles from "./SearchBar.module.css";
import ReactGA from "react-ga4";
import Image from "next/image";
import LoadingEffect from "../loading/LoadingEffect";
import { useRouter, useSearchParams } from "next/navigation";
import { useIsLoading, useSearchTerm, useProductAllInfo } from "@/app/context/StateProvider";
export const SearchBar = () => {
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [isLoading, setIsLoading] = useIsLoading();
  const [searchTerm, setSearchTerm] = useSearchTerm();
  const [productAllInfo, setProductAllInfo] = useProductAllInfo();
  const router = useRouter();
  const searchParams = useSearchParams();
  /*const [headerSearch, setHeaderSearch] = useHeaderSearch();
     const [pagination, setPagination] = usePagination();
     const [isShowModal, setIsShowModal] = useIsShowModal();*/
  const [instantSearchTerm, setInstantSearchTerm] = useState("");
  const [tags, setTags] = useState([{
    id: 1,
    name: 'Digital camera'
  }, {
    id: 2,
    name: 'DSLR camera for travel'
  }, {
    id: 3,
    name: 'Best vlogging camera'
  }, {
    id: 4,
    name: 'Beginner camera'
  }]);
  useEffect(() => {
    // Define a function to handle what should happen once the window is loaded
    //console.log("searchParams", searchParams);
    const handleLoad = () => {
      if (searchParams?.size !== 0 && productAllInfo.length === 0) {
        console.log("decodedSearchParams", searchParams);
        setSearchTerm(searchParams);
        setIsLoading(true);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener("load", handleLoad);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  //This will be trigger when type in "enter" key, different from the click button action
  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Check if the key pressed is 'Enter'

    if (event.key === "Enter" && instantSearchTerm && instantSearchTerm !== "") {
      ReactGA.event({
        category: "Button Click",
        action: "perform search"
      });
      setSearchTerm(instantSearchTerm);
      router.push(`/search/${instantSearchTerm}`);
      setIsLoading(true);
      //handleSearchProducts();
      setShowSearchBar(false);
    }
  };
  const handleSearchButtonClick = () => {
    if (instantSearchTerm && instantSearchTerm !== "") {
      ReactGA.event({
        category: "Button Click",
        action: "perform search"
      });
      console.log("instantSearchTerm", instantSearchTerm);
      setSearchTerm(instantSearchTerm);
      router.push(`/search/${instantSearchTerm}`);
      setIsLoading(true);
      //handleSearchProducts();
      setShowSearchBar(false);
    }
    ;
  };
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstantSearchTerm(event.target.value);
  };
  const handleTagClick = (tagValue: string) => {
    setInstantSearchTerm(tagValue);
    setSearchTerm(tagValue);
    router.push(`/search/${tagValue}`);
    setIsLoading(true);
    setShowSearchBar(false); // Hide the search bar after searching, if needed
    ReactGA.event({
      category: "Search",
      action: "Tag Click",
      label: tagValue
    });
  };
  return <>
        {showSearchBar && !isLoading && <div className={styles.searchContainer}>
				<div className={styles.search_bar}>
					<div className={styles.input_wrapper}>
						<Image className={styles.search_logo} src={SearchIcon} alt="Search" onClick={handleSearchButtonClick} />
						<input type="text" id="searchInput" className={styles.search_box} placeholder="What are you looking for?" onChange={handleSearchInputChange} onKeyDown={handleKeyDown} aria-label="Search for cameras" />
					</div>
				</div>
				
				<div className={styles.tag_box_container} style={{
        textAlign: 'center'
      }}> 
					<div className={styles.tag_box}>
						{tags.map(tag => <div key={tag.id} onClick={() => handleTagClick(tag.name)} className={styles.tag_bar}>
                                <div className={styles.tag_text}>{tag.name}</div>
                            </div>)}
					</div>
				</div>
			</div>}

        {isLoading && <LoadingEffect />}
    </>;
};